<template>
    <div>
        <div class="card w-75 mx-auto">
            <div class="card-body">
                <h3 class="card-title">Votre profil</h3>
                    <div class="form-group col-md-6 mx-auto">
                        <label>Nom</label>
                        <input type="text" class="form-control" v-model="user.Nom" placeholder="Nom">
                    </div>
                    <div class="form-group col-md-6 mx-auto">
                        <label>Prénom</label>
                        <input type="text" class="form-control" v-model="user.username" placeholder="Prénom">
                    </div>
                    <div class="form-group col-md-6 mx-auto">
                        <label>Mail</label>
                        <input type="email" class="form-control" v-model="user.email" placeholder="Email">
                    </div>
                    <div class="form-group col-md-6 mx-auto">
                        <label>INE</label>
                        <input type="text" class="form-control" v-model="user.INE" placeholder="INE">
                    </div>                 
            </div>
        </div>
        <div class="container">
              <div class="row">
                    <div class="col-6">
                        <div class="card">
                            <div class="card-body">
                                <br><br>
                                <table class="radar chaarts" id="radar" :style="radar">
                                    <thead>
                                        <tr>
                                            <th scope="col">Philosophie</th>
                                            <th scope="col">Histoire - Géographie</th>
                                            <th scope="col">EMC (enseignement moral et civique)</th>
                                            <th scope="col">LV1 (langue vivante 1)</th>
                                            <th scope="col">LV2 (langue vivante 2)</th>
                                            <th scope="col">EPS</th>
                                            <th scope="col">Enseignement Scientifique</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span>14</span></td>
                                            <td><span>11</span></td>
                                            <td><span>13</span></td>
                                            <td><span>16</span></td>
                                            <td><span>10</span></td>
                                            <td><span>12</span></td>
                                            <td><span>4</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card" style="width: 35rem;">
                            <div class="card-body">
                                <h5 class="card-title">Tes compétences</h5>
                                <div v-for="competence in competences" :key="competence.id">
                                    <p class="card-text">{{competence}}</p>
                                </div>
                                    <a href="/student/edit/profil" class="btn btn-primary">Modifier</a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/api.js'
import {mapGetters} from 'vuex'

export default {
    name: 'Home',
    data: () => ({
        note_1 : 13,
        note_2 : 15,
        note_3 : 18,
        note_4 : 13,
        note_5 : 15,
        note_6 : 18,
        note_7 : 4,
        competences: {}
    }),
    computed: {
        ...mapGetters(['user']),
        radar() {
            return {
                "--scale" : 20,
                "--step" : 5,
                "--items" : 7,
                "--1": this.note_1,
                "--2": this.note_2,
                "--3": this.note_3,
                "--4": this.note_4,
                "--5": this.note_5,
                "--6": this.note_6,
                "--7": this.note_7,
                "--8": "var(--1)"
            };
        }
    },
    async mounted() {
        const response = await axios.get('users/me')
        this.$store.dispatch('user', response.data)
        axios.get('students/' + this.user.student)
            .then(response => this.competences = response.data.skills.map(skill => skill.Noms));
    }
}
</script>