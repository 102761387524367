<template>
    <div class="container">
        <!--<div class="row">
            <div class="col">
                <router-link to="/" class="navbar-brand">Home</router-link>
            </div>
            <div class="col" v-if="!user">
                <router-link to="/login" class="nav-link">Login</router-link>
            </div>
            <div class="col" v-if="!user">
                <router-link to="/register/students" class="nav-link">Sign up for students</router-link>
            </div>
            <div class="col" v-if="!user">
                <router-link to="/register/schools" class="nav-link">Sign up for schools</router-link>
            </div>
            <div class="col" v-if="user">
                <a href="javascript:void(0)" @click="handleClick" class="nav-link">Logout</a>
            </div>
        </div>-->
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="/">
                <img class="img" src="../assets/img/République.png" alt="Logo Orienty">
                <img class="img" src="../assets/img/Logo.png" alt="Logo Orienty">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" href="/">Home<span class="sr-only"></span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-if="!user" href="/register/student">Inscription étudiante</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-if="!user" href="/register/school">Inscription école</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-if="!user" href="/login">Connexion</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  v-if="user && user.role.type === 'students'" href="/student">Espace lycéens</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-if="user && user.role.type === 'students'" href="/student/profil">Profil</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-if="user && user.role.type === 'schools'" href="/school">Espace école</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  v-if="user && user.role.type === 'schools'"  href="/PGD">PGD</a>
                    </li>
                    <li class="nav-item">
                        <a v-if="user" href="javascript:void(0)" @click="handleClick" class="nav-link">Logout</a>
                    </li>                    
                </ul>
            </div>
        </nav>
    </div>
</template>


<script>
import axios from '@/api.js'
//import Error from '../components/Error'
import {mapGetters} from 'vuex'

export default {

  name: 'App',
    components: {
        //Error
  },
  methods: {
        handleClick() {
            localStorage.removeItem('token');
            this.$store.dispatch('user', null)
            this.$router.push('/')
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
  async created () {
      const response = await axios.get('users/me', {
        headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }})
      this.$store.dispatch('user', response.data)
      console.log(response, "test")
      console.log(this.user.role)
  }
}
</script>