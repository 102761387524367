<template>
  <div id="app">
    <h3 class="message">Pour des raisons techniques, notre site peut parfois avoir un temps de chargement relativement long</h3>

    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="../assets/img/art.jpg" alt="First slide">
          <div class="title carousel-caption d-none d-md-block">
            <h5>Arts et industries culturelle</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/law2.jpg" alt="Second slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Droit et sciences politiques</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/informatic.jpg" alt="Third slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Informatique, mathématiques et numérique</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/litterature.jpg" alt="Fourth slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Lettres, langues et communication</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/health2.jpg" alt="Fifth slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Santé</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/science.jpg" alt="Sixth slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Sciences du vivant et géosciences</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/economy.jpg" alt="Seventh slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Sciences économiques et de gestion</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/social2.jpg" alt="Eighth slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Sciences humaines et sociales</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/ingeniery.jpg" alt="Nineth slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Sciences, technologie, ingénierie et mathématiques</h5>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

  export default {
    name: 'Home',
    computed: {
      ...mapGetters(['user'])
    }
}
</script>