<template>
    <div>
        <!--<form @submit.prevent="handleSubmit">
            <error v-if="error" :error="error" />
            <h3>Login</h3>
            <div class="form-group">
                <input type="text" class="form-control" v-model="email" placeholder="Email"/>
            </div>
            <br>
            <div class="form-group">
                <input type="password" class="form-control" v-model="password" placeholder="Mot de passe"/>
            </div>
            <br>
            <button class="btn btn-primary mybtn">Login</button>
        </form>-->
        <div class="card w-75 mx-auto">
            <img class="card-img-top img-fluid" src="../assets/img/Books.jpg" alt="Card image cap">
            <div class="card-body">
                <h3 class="card-title">Login</h3>
                <!--<form @submit.prevent="handleSubmit">
                    <error v-if="error" :error="error" />
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="email" placeholder="Email"/>
                    </div>
                    <br>
                    <div class="form-group">
                        <input type="password" class="form-control" v-model="password" placeholder="Mot de passe"/>
                    </div>
                    <br>
                    <button class="btn btn-primary mybtn">Login</button>
                </form>-->
                <form @submit.prevent="handleSubmit">
                    <error v-if="error" :error="error" />
                    <div class="form-group col-md-6 mx-auto">
                        <label></label>
                        <input type="email" class="form-control" v-model="email" placeholder="Email">
                    </div>
                    <div class="form-group col-md-6 mx-auto">
                        <label></label>
                        <input type="password" class="form-control" v-model="password" placeholder="Password">
                    </div>
                    <button type="submit" class="submit1 btn btn-light">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/api.js'
import Error from '../components/Error'

export default {
    name: 'Login',
    components: {
        Error
    },
    data() {
        return {
            email: '',
            password: '',
            error: ""
        }
    },
    methods: {
        async handleSubmit() {
            try {
            const response = await axios.post('auth/local ', {
                identifier: this.email,
                password: this.password
            })

            localStorage.setItem('token', response.data.jwt)
            this.$store.dispatch('user', response.data.user)
            this.$router.push('/')
            } catch (e) {
                this.error = "Mot de passe ou email invalide"
            }
        }
    }
}
</script>
