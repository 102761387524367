import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import StudentRegister from '../views/StudentRegister.vue'
import SchoolRegister from '../views/SchoolRegister.vue'
import Login from '../views/Login.vue'
import Student from '../views/Student.vue'
import StudentProfil from '../views/StudentProfil.vue'
import EditStudentProfil from '../views/StudentProfilEdit.vue'
import School from '../views/School.vue'
import PGD from '../views/PGD.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/register/student',
        name: 'StudentRegister',
        component: StudentRegister
    },
    {
        path: '/register/school',
        name: 'SchoolRegister',
        component: SchoolRegister
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/student',
        name: 'Student',
        component: Student
    },
    {
        path: '/student/profil',
        name: 'StudentProfil',
        component: StudentProfil
    },
    {
        path: '/student/edit/profil',
        name: 'StudentProfilEdit',
        component: EditStudentProfil
    },
    {
        path: '/school',
        name: 'School',
        component: School
    },
    {
        path: '/PGD',
        name: 'PGD',
        component: PGD
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router