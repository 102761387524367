import axios from 'axios'
const axiosApiInstance = axios.create();

axiosApiInstance.defaults.baseURL = "https://strapi-scoolize.onrender.com"

axiosApiInstance.interceptors.request.use(
    async config => {
      if(localStorage.getItem('token')) {
        config.headers = { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
      return config;
    },
    error => {
      Promise.reject(error)
});
  
/*
axiosApiInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();            
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
});
*/

export default axiosApiInstance;