<template>
    <div>
        <form v-if="user" @submit.prevent="onSubmit()">
        <div class="nuage">
            <h2>Centres d'interets</h2>
            <div class="wrap">
                <div v-for="competence in competences" :key="competence.id">
                    <input type="checkbox" class="demo" :id="'ss' + competence.id" :value="competence.id" v-model="competencesSelected">
                    <label :for="'ss' + competence.id">{{competence.Noms}}</label>
                </div>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn-primary">Submit</button>
        </div>
        </form>
        </div>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from '@/api.js'

  export default {
    name: 'Home',
    computed: {
      ...mapGetters(['user'])
    },
    data() {
      return {
        competences: [],
        competencesSelected: [],
        error: ""
      }
    },
    async mounted() {
        axios.get('skills')
            .then(response => this.competences = response.data)
            .catch(error => console.log(error));

        const response = await axios.get('users/me')
        this.$store.dispatch('user', response.data)
        console.log(this.user.student)
         axios.get('students/' + this.user.student)
            .then(response => this.competencesSelected = response.data.skills.map(skill => skill.id))
            .catch(error => console.log(error));

    },
    methods: {
        onSubmit() {
            if (this.user.student != null) {
            axios.put('students/' + this.user.student, {skills: this.competencesSelected})
                .then(response => console.log(response.data))
                .catch(error => console.log(error));
        } else {
            axios.post('students/', {user: this.user.id, skills: this.competencesSelected})
                .then(response => console.log(response.data))
                .catch(error => console.log(error))
        }
        this.$router.push('/student/profil')
        }
    }
}
</script>

<style>
button {
    height: auto;
    width: auto;
    border-radius: 5px;
    background: #d1c6c6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}

button:hover {
    width: auto;
    height: auto;
    background: #F3C10A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}

button:checked {
    width: auto;
    height: auto;
    background: #F3C10A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}

.nuage {
    border: solid;
    border-color: #00205B;
    margin: 10px;
    padding: 0px;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
}

h2 {
    text-align: center;
}

input[type="checkbox"].demo {
    display: none;
}

input[type="checkbox"].demo+label {
    border: 1px solid transparent;
    height: auto;
    width: auto;
    border-radius: 5px;
    background: #d1c6c6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}

input[type="checkbox"].demo:hover+label {
    width: auto;
    height: auto;
    background: rgba(243, 193, 10, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}

input[type="checkbox"].demo:checked+label {
    width: auto;
    height: auto;
    background: #F3C10A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}
</style>