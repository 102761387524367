<template>
    <div class="card w-75 mx-auto">
        <img class="card-img-top img-fluid" src="../assets/img/Books.jpg" alt="Card image cap">
        <div class="card-body">
            <h3 class="card-title">Sign up</h3>
            <form @submit.prevent="handleSubmit">
                <error v-if="error" :error="error" />
                <div class="form-group col-md-6 mx-auto">
                    <label></label>
                    <input type="text" class="form-control" v-model="username" placeholder="Nom de l'établissement">
                </div>
                <div class="form-group col-md-6 mx-auto">
                    <label></label>
                    <input type="email" class="form-control" v-model="email" placeholder="Email">
                </div>
                <div class="form-group col-md-6 mx-auto">
                    <label></label>
                    <input type="password" class="form-control" v-model="password" placeholder="Password">
                </div>
                <button class="submit1 btn btn-light">S'inscrire</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from '@/api.js'
import Error from '../components/Error'

export default{
    name: 'Register',
    components: {
        Error
    },
    data() {
        return {
            username:'',
            email: '',
            password:'',
            error: ''
        }
    },
    methods: {
        handleSubmit(){            
            const data = {
                username: this.username,
                email: this.email,
                password: this.password,                
                role: 4
            }
            console.log(data)
            axios.post('users', data)
                .then(
                    res => {
                        console.log(res)
                        this.$router.push('/login')
                    }
                ).catch(
                    err => {
                        const response = err.response
                        console.log(err)
                        console.log(response.data.message)
                        this.error = response.data.message
                    }
                )            
        }
    }
}
</script>