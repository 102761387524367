<template>
    <div id="app">
        <div class="container">
            <Nav />
        </div>

        <div class="auth-wrapper">
            <div class="auth-inner">
                <router-view />
            </div>
        </div>
        <div class="container">
            <Footer />
        </div>
    </div>
</template>

<script>
import Nav from './components/Nav'
import Footer from './components/Footer'

export default {
    name: 'App',
        components: {
            Nav,
            Footer
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #00205B;
}

#nav a.router-link-exact-active {
    color: #00205B;
    text-decoration-color: #FFAC33;
}
</style>
