<template>
    <div>
        <h2>Informations générales</h2>
        <div class="info_et_profil">
            <strong>Nom de la formation : </strong><input v-model="formation_name" placeholder=formation_name width="auto"><br>

            <strong>Temps de formation : </strong><input v-model="duration" placeholder="duration" id="number" type="number" value="5" min="2" max="8" width="5px"> ans <br>
            <strong>Diplôme de fin de cursus : </strong><input v-model="diplome" placeholder=diplome><br>
            <strong>Description de la formation :</strong> <input v-model="description" placeholder=description><br>
            <strong>Site Web :</strong> <a href='https://www.epitech.eu/digital/'>{{website}}</a>
        </div>
        <h2>Profils recherchés</h2>
        <br>
        <div class="info_et_profil">
            <ul>
                <li>
                    <div>
                        <div>A fait des mathématiques jusqu'en</div>
                        <div>
                            <select class="form-control" id="niveauMaths">
                                <option selected>Séléctionnez une option</option>
                                <option value="1">2nd</option>
                                <option value="2">1ère</option>
                                <option value="3">Tle</option>
                            </select>.
                        </div>                                                 
                    </div>
                    </li>
                <li>A une moyenne générale compris entre <input type="number" value="12" min="0" max="20" width="5px"> et <input type="number" value="15" min="0" max="20" width="5px">.</li>
            </ul>
        </div>
        <br>
        <h2>Horizons à favoriser</h2>
        <div class="classement_horizons">
            <div class="number"><span class="number_item" v-for="horizon in horizons_number" :key="horizon.number">{{horizon.number}}</span></div>
            <div>
                <draggable :list="horizons" class="horizons">
                    <span class="item" v-for="horizon in horizons" :key="horizon.name">{{horizon.name}}</span>
                </draggable>
            </div>
        </div>
        <br>
        <h2>Candidatures</h2>
        <table>
            <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Lettre de motivation</th>
            </tr>
            <tr v-for="candidat in candidats" :key="candidat">
                <td>{{candidat.name}}</td>
                <td>{{candidat.surname}}</td>
                <td>{{candidat.motivation}}</td>
            </tr>
        </table>
    </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
    components:{
        draggable
    },
    data () {
        return {
            horizons: [
                {name : "Arts et industries culturelles"},
                {name : "Droit et sciences politiques"},
                {name : "Droit et sciences politiques"},
                {name : "Lettres, langues et communication"},
                {name : "Santé"},
                {name : "Sciences du vivant et géosciences"},
                {name : "Sciences économiques et de gestion"},
                {name : "Sciences humaines et sociales"},
                {name : "Sciences, technologie, ingénierie et mathématiques"},
            ],
            horizons_number: [
                {number : 1},
                {number : 2},
                {number : 3},
                {number : 4},
                {number : 5},
                {number : 6},
                {number : 7},
                {number : 8},
                {number : 9},
            ],
            candidats: [
                {
                    name: "Robin",
                    surname: "MAHE",
                    motivation: "Lettre de motivation"
                },
                {
                    name: "Héloïse",
                    surname: "Le Geldron",
                    motivation: "Lettre de motivation"
                },
                {
                    name: "Marion",
                    surname: "Bouquet",
                    motivation: "Lettre de motivation"
                }
            ],
            formation_name: "Programme Global Digital",
            duration: 5,
            diplome: "Titre RNCP niveau 7",
            description: "Formation trop cool",
            website: "https://www.epitech.eu/digital/",
        }
    }
}
</script>

<style>
.item {
    background:#F3C10A ;
    width: 400px;
    border: solid 1px;
    border-color: #00205B;
    padding: 2px;
    margin: auto;
    margin-top: 2px;
    cursor: pointer;
}

.number_item {
    background:#F3C10A ;
    border: solid 1px;
    border-color: #00205B;
    padding: 2px;
    margin: auto;
    margin-top: 2px;
}

div.info_et_profil {
    text-align: left;
    padding-left: 20px;

}

.horizons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.number {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.classement_horizons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


table {
    text-align: center;
    margin: auto;
}
</style>